import translation from "./translation.json";
import dispatching from "./dispatching.json";
import sorting from "./sorting.json";
import smartMatch from "./smart-match.json";
import cargo from "./cargo.json";
import auth from "./auth.json";
import fleet from "./fleet.json";
import upcoming from "./upcoming.json";
import components from "./components.json";
import economic from "./economic.json";
import types from "./types.json";
import notifications from "./notifications.json";
import invoicing from "./invoicing.json";
import settings from "./settings.json";
import navigation from "./navigation.json";
import modals from "./modals.json";
import expenses from "./expenses.json";
import emissions from "./emissions.json";
import businessCentral from "./business-central.json";
import shipmentModal from "./shipment-modal.json";
import formBuilder from "./form-builder.json";
import liveView from "./live-view.json";
import cargosReport from "./cargos-report.json";
import shipmentDriverReport from "./shipment-driver-report.json";
import gsGroup from "./gs-group.json";
import schedules from "./schedules.json";
import extraCO2Types from "./extra-co2-types.json";

// eslint-disable-next-line
export default {
  translation,
  dispatching,
  sorting,
  smartMatch,
  fleet,
  cargo,
  auth,
  upcoming,
  components,
  economic,
  types,
  notifications,
  invoicing,
  settings,
  navigation,
  modals,
  expenses,
  emissions,
  businessCentral,
  shipmentModal,
  formBuilder,
  liveView,
  cargosReport,
  shipmentDriverReport,
  gsGroup,
  schedules,
  extraCO2Types
};
