import { useCalendarViewControls } from "./CalendarViewControlContext";
import { useMemo } from "react";
import clsx from "clsx";
import "./CalendarViewTimelineTopBar.scss";

export const CalendarViewTimelineTopBar = () => {
  const { viewTimeInterval, colWidth } = useCalendarViewControls();

  const times: { value: string; shouldShow: boolean }[] = useMemo(() => {
    const timesArray = [];
    const loopCount = viewTimeInterval === "day" ? 25 : 24;
    for (let t = 0; t < loopCount; t++) {
      const val = t < 10 ? `0${t}:00` : `${t}:00`;
      let shouldShow = true;
      if (viewTimeInterval === "week") {
        if (colWidth < 17) {
          shouldShow = t % 12 === 0;
        } else if (colWidth < 29) {
          shouldShow = t % 6 === 0;
        } else if (colWidth < 70) {
          shouldShow = t % 3 === 0;
        } else {
          shouldShow = true;
        }
        if (val === "24:00" && viewTimeInterval === "week") {
          shouldShow = false;
        }
      }
      timesArray.push({
        value: val,
        shouldShow,
      });
    }
    return timesArray;
  }, [viewTimeInterval, colWidth]);

  const days: string[] = useMemo(() => {
    return viewTimeInterval === "day"
      ? ["Day"]
      : [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
  }, [viewTimeInterval]);

  return (
    <div className="timeline-top-bar">
      {days.map((d, i) => (
        <div key={i}>
          <div>{d}</div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `${colWidth}px repeat(${times.length - 1}, ${colWidth}px)`,
            }}
          >
            {times.map((t) => {
              const classes = clsx("timeline-top-bar-item-wrapper", {
                "timeline-top-bar-item-wrapper--hidden": !t.shouldShow,
              });
              return (
                <div key={t.value} className={classes}>
                  {t.value}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CalendarViewTimelineTopBar;
