import { customAlphabet } from "nanoid";

// Avoid using
// * the letter O and number 0, as they can be confused
// * The letter I and the number 1, as they can be confused
// L is not a problem, as we use only upper-case letters.
const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ";
const number = "23456789";

const letterGen = customAlphabet(letters, 4);
const numbersGen = customAlphabet(number, 6);

export const createCargoRef = () => `${letterGen()}-${numbersGen()}`;
