import { RootState } from "../../../redux-store";

export const selectDataForCalendarView = (state: RootState) => {
  return state.data.calendarView.data;
};

export const selectRequestStatusForCalendarView = (state: RootState) => {
  return state.data.calendarView.status;
};

export const selectViewTimeInterval = (state: RootState) => {
  return state.data.calendarView.selectedInterval;
};

export const selectStartDate = (state: RootState) => {
  return state.data.calendarView.startDate;
};
