import { useAppDispatch, useSelector } from "../../redux-store";
import { useEffect } from "react";
import {
  changeSelectedIntervalAndLoadData,
  loadCalendarViewData,
  nextIntervalSelected,
  previousIntervalSelected,
} from "../../ducks/data/calendar-view";
import {
  selectDataForCalendarView,
  selectStartDate,
} from "../../ducks/data/calendar-view/selectors";
import DoraButton from "../../Components/Toolkit/DoraButton";
import "./CalendarViewPage.scss";
import {
  selectTrailer,
  selectTrailers,
} from "../../ducks/data/trailers/selectors";
import { loadTrailers } from "../../ducks/data/trailers";
import { loadDrivers } from "../../ducks/data/drivers";
import {
  CalendarViewRouteData,
  CalendarViewTrailerSectionData,
} from "../../ducks/data/calendar-view/types";
import { useCalendarViewControls } from "./CalendarViewControlContext";
import CalendarViewRouteWithStops from "./CalendarViewRouteWithStops";
import CalendarViewTimelineTopBar from "./CalendarViewTimelineTopBar";
import { DateTime } from "luxon";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const CalendarViewPage = () => {
  const dispatch = useAppDispatch();

  // const calendarViewStatus = useSelector(selectRequestStatusForCalendarView);
  const calendarViewData = useSelector(selectDataForCalendarView);
  const searchStartDate = useSelector(selectStartDate);

  const {
    zoomFactor,
    increaseZoom,
    decreaseZoom,
    showCollisionsMultipleRows,
    setShowCollisionsMultipleRows,
    onTimeSelectionClick,
    viewTimeInterval,
  } = useCalendarViewControls();

  useEffect(() => {
    dispatch(changeSelectedIntervalAndLoadData(viewTimeInterval));
    dispatch(loadTrailers());
    dispatch(loadDrivers());
  }, [dispatch]);

  // TODO: replace with actual listening on events and fetching data then
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(loadCalendarViewData());
    }, 1300);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  const onIntervalClick = (interval: string) => {
    onTimeSelectionClick(interval);
  };

  const onPreviousClick = () => {
    dispatch(previousIntervalSelected);
  };

  const onNextClick = () => {
    dispatch(nextIntervalSelected);
  };

  const trailers = useSelector(selectTrailers);

  return (
    <div className="calendar-view-page">
      <div className="flex justify-between p-4">
        <div className="flex gap-1">
          <DoraButton
            variant={viewTimeInterval === "day" ? "primary-filled" : "primary"}
            onClick={() => onIntervalClick("day")}
          >
            Day
          </DoraButton>
          <DoraButton
            variant={viewTimeInterval === "week" ? "primary-filled" : "primary"}
            onClick={() => onIntervalClick("week")}
          >
            Week
          </DoraButton>
        </div>
        <div className="flex gap-2 items-center">
          <DoraButton variant={"primary"} onClick={onPreviousClick}>
            <ArrowBack style={{ fontSize: 18 }} />
          </DoraButton>
          <div>
            {viewTimeInterval === "day" &&
              searchStartDate.toFormat("dd.MM.yyyy")}
            {viewTimeInterval === "week" &&
              searchStartDate.startOf("week").toFormat("dd.MM.yyyy") +
                " - " +
                searchStartDate.endOf("week").toFormat("dd.MM.yyyy")}
          </div>
          <DoraButton variant={"primary"} onClick={onNextClick}>
            <ArrowForward style={{ fontSize: 18 }} />
          </DoraButton>
        </div>
        <div className="flex gap-1 justify-end">
          <DoraButton
            variant="primary"
            disabled={zoomFactor === 1}
            onClick={decreaseZoom}
          >
            -
          </DoraButton>
          <DoraButton
            variant="primary"
            disabled={zoomFactor === 10}
            onClick={increaseZoom}
          >
            +
          </DoraButton>
          {/*<DoraButton*/}
          {/*  variant={*/}
          {/*    groupStopsWhenMovingInSameRoute ? "primary-filled" : "primary"*/}
          {/*  }*/}
          {/*  onClick={() =>*/}
          {/*    setGroupStopsWhenMovingInSameRoute(*/}
          {/*      !groupStopsWhenMovingInSameRoute*/}
          {/*    )*/}
          {/*  }*/}
          {/*>*/}
          {/*  {groupStopsWhenMovingInSameRoute ? "Ungroup stops" : "Group stops"}*/}
          {/*</DoraButton>*/}
          <DoraButton
            variant="primary"
            onClick={() =>
              setShowCollisionsMultipleRows(!showCollisionsMultipleRows)
            }
          >
            {showCollisionsMultipleRows
              ? "Collapse collisions"
              : "Expand collisions"}
          </DoraButton>
        </div>
      </div>
      <div className="overflow-auto">
        <CalendarViewTimelineTopBar />
        {[...calendarViewData]
          .sort((trailer1, trailer2) => {
            const t1 = trailers.find((t) => t.id === trailer1.trailerId)!;
            const t2 = trailers.find((t) => t.id === trailer2.trailerId)!;
            return (t1.alias || t1.number).localeCompare(t2.alias || t2.number);
          })
          .map((trailerSection) => (
            <CalendarViewTrailerRowsSection
              key={trailerSection.trailerId}
              trailerRowsSection={trailerSection}
            />
          ))}
      </div>
    </div>
  );
};

const CalendarViewTrailerRowsSection = ({
  trailerRowsSection,
}: {
  trailerRowsSection: CalendarViewTrailerSectionData;
}) => {
  const rowsOfOverlappingRoutes: { routes: CalendarViewRouteData[] }[] = [];
  for (const route of trailerRowsSection.routes) {
    let addedToRow = false;
    for (const row of rowsOfOverlappingRoutes) {
      let canFitInRow = true;
      for (const exisingRouteOnRow of row.routes) {
        const existingRouteOnRowStartDateTime = DateTime.fromFormat(
          exisingRouteOnRow.routeStartDate,
          "yyyy-MM-dd"
        ).set({
          hour: parseInt(exisingRouteOnRow.routeStartTime.split(":")[0]),
          minute: parseInt(exisingRouteOnRow.routeStartTime.split(":")[1]),
        });
        const existingRouteOnRowEndDateTime = DateTime.fromFormat(
          exisingRouteOnRow.routeEndDate,
          "yyyy-MM-dd"
        ).set({
          hour: parseInt(exisingRouteOnRow.routeEndTime.split(":")[0]),
          minute: parseInt(exisingRouteOnRow.routeEndTime.split(":")[1]),
        });
        const routeStartDateTime = DateTime.fromFormat(
          route.routeStartDate,
          "yyyy-MM-dd"
        ).set({
          hour: parseInt(route.routeStartTime.split(":")[0]),
          minute: parseInt(route.routeStartTime.split(":")[1]),
        });
        const routeEndDateTime = DateTime.fromFormat(
          route.routeEndDate,
          "yyyy-MM-dd"
        ).set({
          hour: parseInt(route.routeEndTime.split(":")[0]),
          minute: parseInt(route.routeEndTime.split(":")[1]),
        });

        const overlaps =
          existingRouteOnRowStartDateTime < routeEndDateTime &&
          existingRouteOnRowEndDateTime > routeStartDateTime;
        if (overlaps) {
          canFitInRow = false;
          break;
        }
      }
      if (canFitInRow) {
        row.routes.push(route);
        addedToRow = true;
        break;
      }
    }
    if (!addedToRow) {
      rowsOfOverlappingRoutes.push({ routes: [route] });
    }
  }

  return (
    <div className="trailer-driver-full-row">
      <div className="trailer-driver-section">
        <TrailerAliasAndNumber trailerId={trailerRowsSection.trailerId} />
      </div>
      <div>
        {rowsOfOverlappingRoutes.map((rowWithRoutes, i) => (
          <div key={i} className="trailer-route-row-section">
            {rowWithRoutes.routes.map((route, j) => (
              <CalendarViewRouteWithStops key={j} route={route} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const TrailerAliasAndNumber = ({ trailerId }: { trailerId: string }) => {
  const trailer = useSelector(selectTrailer(trailerId));
  return (
    <div>
      <div style={{ fontSize: 16 }}>{trailer?.alias || trailer?.number}</div>
      {trailer?.alias && (
        <div style={{ fontSize: 14, color: "darkgray" }}>{trailer.number}</div>
      )}
    </div>
  );
};

// const DriverName = ({ driverId }: { driverId: string }) => {
//   const driver = useSelector(selectDriver(driverId));
//   return (
//     <div style={{ fontSize: 14 }}>
//       {driver
//         ? [`${driver.firstName.charAt(0)}.`, driver.lastName].join(" ")
//         : ""}
//     </div>
//   );
// };

export default CalendarViewPage;
