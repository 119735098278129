import axios, { getData } from "../axios";
import { createErrorReportingAsyncThunk } from "./helpers";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";

const cargoOrdersT = t.strict({
  updatedCargoOrders: t.array(
    t.strict({ routeId: t.string, cargoOrder: t.array(t.string) })
  ),
});

export const moveCargo = createErrorReportingAsyncThunk(
  "/app/dispatch/moveCargo",
  async (arg: {
    sourceRouteId: string | null;
    destRouteId: string | null;
    cargoId: string;
    destIndex?: number;
  }): Promise<t.TypeOf<typeof cargoOrdersT>> => {
    const { sourceRouteId, destRouteId, destIndex, cargoId } = arg;
    if (destRouteId || sourceRouteId) {
      // Don't post if rearranging unassigned cargo
      return axios
        .post(`/api/cargos/${cargoId}/move-to-route`, {
          destRouteId,
          destIndex,
        })
        .then(getData)
        .then(tPromise.decode(cargoOrdersT));
    } else {
      return { updatedCargoOrders: [] };
    }
  }
);
