import { createSlice } from "@reduxjs/toolkit";
import axios, { getData } from "../../../axios";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { decode } from "io-ts-promise";
import { CargoEmissionsResponse, cargoEmissionsResponsesT } from "./types";
import { DateTime } from "luxon";
import * as tPromise from "io-ts-promise";
import * as t from "io-ts";
import { nullable, decimal } from "dora-contracts";
import Decimal from "decimal.js-light";

const prefix = "data/cargo-emissions";

type StatusNotLoaded = {
  status: "UNINITIALIZED" | "LOADING" | "LOAD_ERROR";
};

const mergeEmission = (input: CargoEmissionsResponse) => ({
  ...input.cargoView,
  ...input.cargoEmissions,
  clientId: input.clientId,
});

export type CargoEmissionData = ReturnType<typeof mergeEmission>;

type StatusLoaded = {
  status: "LOADED";
  data: CargoEmissionData[];
};

export type EmissionsData = StatusNotLoaded | StatusLoaded;

type State = {
  status: EmissionsData;
  configuration?: {
    avgMileagePrLiter: Decimal | null;
  };
};

export const loadConfiguration = createErrorReportingAsyncThunk(
  `${prefix}/load-configuration`,
  async () => {
    const result = await axios
      .get("/api/org")
      .then((x) => x.data)
      .then(
        tPromise.decode(
          t.strict({
            avgMileagePrLiter: nullable(decimal),
          })
        )
      );
    return result;
  }
);

export const updateConfiguration = createErrorReportingAsyncThunk(
  `${prefix}/update-configuration`,
  async (mileage: Decimal | null) => {
    const result = await axios
      .put("/api/org/avg-mileage", {
        avgMileagePrLiter: mileage?.toString() || null,
      })
      .then((x) => x.data)
      .then(
        tPromise.decode(
          t.strict({
            avgMileagePrLiter: nullable(decimal),
          })
        )
      );
    return result;
  }
);

const initialState: State = {
  status: { status: "UNINITIALIZED" },
};

export const loadEmissions = createErrorReportingAsyncThunk(
  `${prefix}/load-emissions`,
  async (input?: { clientId?: string | null; month?: string | null }) => {
    const date = input?.month && DateTime.fromFormat(input?.month, "yyyy-MM");
    const clientId = input?.clientId && { clientId: input.clientId };
    const dates = date
      ? {
          fromDate: date.toFormat("yyyy-MM-dd"),
          toDate: date.endOf("month").toFormat("yyyy-MM-dd"),
        }
      : {};
    const result = axios
      .get("/api/cargo-emissions", {
        params: { ...clientId, ...dates },
      })
      .then(getData)
      .then(decode(cargoEmissionsResponsesT))
      .then((data) => data.map(mergeEmission));
    return result;
  }
);

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    unloadConfiguration(state) {
      delete state.configuration;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEmissions.pending, (state) => {
        state.status = {
          status: "LOADING",
        };
      })
      .addCase(loadEmissions.rejected, (state) => {
        state.status = { status: "LOAD_ERROR" };
      })
      .addCase(loadEmissions.fulfilled, (state, action) => {
        state.status = { status: "LOADED", data: action.payload };
      })
      .addCase(updateConfiguration.fulfilled, (state, action) => {
        state.configuration = action.payload;
      })
      .addCase(loadConfiguration.fulfilled, (state, action) => {
        state.configuration = action.payload;
      });
  },
});

export default slice.reducer;
export const { unloadConfiguration } = slice.actions;
