import { useDrag } from "react-dnd";
import { CalendarViewDrawnStop } from "./interfaces";
import { useStableNavigate } from "../../Components/StableNavigateContext";
import { CALENDAR_VIEW_DRAGGABLE_CARGO_STOP } from "./constants";

const CalendarViewStop = ({
  drawnStop,
  leftOffset,
}: {
  drawnStop: CalendarViewDrawnStop;
  leftOffset: number;
}) => {
  const navigate = useStableNavigate();

  let width = drawnStop.width - 4; // -4 to offset the route border
  if (width < 1) {
    width = 1;
  }

  const onClick = () => {
    navigate({ hash: `cargo=${drawnStop.cargoId}` });
  };

  const [{ isDragging }, drag] = useDrag({
    type: CALENDAR_VIEW_DRAGGABLE_CARGO_STOP,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: (monitor) => {
      const dragOffset = monitor.getClientOffset();
      return {
        drawnStop,
        simpleDragOffset: dragOffset!.x,
      };
    },
  });

  return (
    <>
      <div
        ref={drag}
        onClick={onClick}
        className="calendar-view-stop"
        style={{
          background: drawnStop.color,
          left: drawnStop.startPosX - leftOffset,
          width,
          border: `2px solid ${drawnStop.color}`,
        }}
      >
        <div
          style={{
            width: "100%",
            height: 1,
            position: "relative",
          }}
        >
          {(drawnStop.startTimeAssumed || drawnStop.stopTimeAssumed) && (
            <div
              style={{
                position: "absolute",
                top: -10,
                right: -4,
                zIndex: 1,
              }}
            >
              !!!
            </div>
          )}
        </div>
        {drawnStop.place}
      </div>
    </>
  );
};

export default CalendarViewStop;
