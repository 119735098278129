import React, { Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TrailerDialog from "./modals/trailers/TrailerDialog";
import TeamDialog from "./modals/TeamDialog";
import { lazyRetry } from "../helpers";
import { useAppDispatch } from "../redux-store";
import NewsModal from "./modals/NewsModal";
import EndRouteDialogPrompt from "./modals/EndRouteDialogPrompt";
import { CargoFormData } from "../ducks/app/cargo-dialog/types";
import * as cargoDialogActions from "../ducks/app/cargo-dialog";
import * as cargoTemplateDialogActions from "../ducks/app/cargo-template-dialog";
import axios, { getData } from "../axios";
import { useStableNavigate } from "./StableNavigateContext";
import AddEditCargoModalWrapper from "./modals/add-edit-cargo";
import RequireAuth from "./RequireAuth";
import AddEditCargoTemplateModalWrapper from "./modals/add-edit-cargo-template";

const LazyOrderDialog = React.lazy(() =>
  lazyRetry(() => import("./modals/OrderModal"), "order-modal")
);

const DriverDialog = React.lazy(() =>
  lazyRetry(() => import("./modals/drivers/DriverDialog"), "driver-dialog")
);

const OrderModalLoader = (props: { orderId: string; onClose: () => void }) => {
  return (
    <Suspense>
      <LazyOrderDialog {...props} />
    </Suspense>
  );
};

const CargoModal = () => {
  const { hash } = useLocation();
  const params = new URLSearchParams(hash.substring(1));
  const cargoId = params.get("cargo");
  const routeId = params.get("route");
  const navigate = useStableNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!cargoId) {
      return;
    }

    if (cargoId === "new") {
      if (routeId) {
        dispatch(cargoDialogActions.createNewCargo());
      } else {
        dispatch(cargoDialogActions.createNewCargo());
      }
    }
  }, [dispatch, cargoId, routeId]);

  const onClose = ({
    formData,
    cargoId,
  }: {
    formData?: CargoFormData;
    cargoId?: string;
  }) => {
    dispatch(cargoDialogActions.close(formData));
    navigate({ hash: "" });
    if (cargoId) {
      axios
        .get(`/api/cargos/${cargoId}/should-suggest-archive-route`)
        .then((res) => {
          const data: { routeId: string; routeRef: string } | null =
            getData(res);
          setRouteToBeArchivedForPrompt(data);
        });
    }
  };

  const [routeToBeArchivedForPrompt, setRouteToBeArchivedForPrompt] = useState<{
    routeId: string;
    routeRef: string;
  } | null>(null);

  let shipmentEditModalComponent = null;
  if (cargoId) {
    if (cargoId === "new") {
      shipmentEditModalComponent = (
        <AddEditCargoModalWrapper
          onClose={onClose}
          routeId={routeId || undefined}
        />
      );
    } else {
      shipmentEditModalComponent = (
        <AddEditCargoModalWrapper onClose={onClose} cargoId={cargoId} />
      );
    }
  }

  return (
    <>
      {routeToBeArchivedForPrompt && (
        <EndRouteDialogPrompt
          route={routeToBeArchivedForPrompt}
          onClose={() => setRouteToBeArchivedForPrompt(null)}
        />
      )}
      {shipmentEditModalComponent}
    </>
  );
};

const CargoTemplateModal = () => {
  const { hash } = useLocation();
  const params = new URLSearchParams(hash.substring(1));
  const cargoTemplateId = params.get("cargoTemplate");
  const routeTemplateId = params.get("routeTemplateId");
  const navigate = useStableNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!cargoTemplateId) {
      return;
    }

    if (cargoTemplateId === "new") {
      dispatch(cargoTemplateDialogActions.createNewCargoTemplate());
    }
  }, [dispatch, cargoTemplateId]);

  const onClose = () => {
    dispatch(cargoTemplateDialogActions.close());
    navigate({ hash: "" });
  };

  let shipmentTemplateEditModalComponent = null;
  if (cargoTemplateId) {
    if (cargoTemplateId === "new") {
      shipmentTemplateEditModalComponent = (
        <AddEditCargoTemplateModalWrapper
          routeTemplateId={routeTemplateId}
          onClose={onClose}
        />
      );
    } else {
      shipmentTemplateEditModalComponent = (
        <AddEditCargoTemplateModalWrapper
          routeTemplateId={routeTemplateId}
          cargoTemplateId={cargoTemplateId}
          onClose={onClose}
        />
      );
    }
  }

  return shipmentTemplateEditModalComponent;
};

const OrderModal = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  if (!hash || !hash.length) return null;
  const params = new URLSearchParams(hash.substring(1));
  const orderId = params.get("order");
  if (!orderId) return null;
  const closeModal = () => {
    navigate({ hash: "" }, { replace: true });
  };
  return <OrderModalLoader orderId={orderId} onClose={closeModal} />;
};

const Dialogs = () => (
  <>
    <RequireAuth>
      <Suspense fallback={null}>
        <DriverDialog />
        <CargoModal />
        <CargoTemplateModal />
        <TrailerDialog />
        <TeamDialog />
        <OrderModal />
      </Suspense>
    </RequireAuth>
    <NewsModal />
  </>
);

export default Dialogs;
