import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { getData } from "../../../../axios";
import {
  AppDispatch,
  AppThunkAction,
  RootState,
} from "../../../../redux-store";
import { createErrorReportingAsyncThunk } from "../../../helpers";
import { getRouteStops, stopOrderUpdated } from "../../../routes";
import { routeInfoStopsT, moveStopRequestT } from "dora-contracts";
import * as tPromise from "io-ts-promise";
import * as notificationActions from "../../../notifications";
import { setHoveredCargoId } from "../../../cargo";
import { beginDragStop, endDrag } from "../../dispatching";
import * as cargoActions from "../../../data/cargos";
import * as selectors from "./selectors";
import { RouteInfoStop } from "./types";
import values from "lodash/values";

const prefix = "app/route-info/stops";

export const beginDrag = beginDragStop;

type RouteStop = RouteInfoStop;

type State = {
  routeId?: string;
  originalStops: RouteStop[] | null;
  stops: RouteStop[] | null;
  movedStop?: RouteStop;
  saving: boolean;
};

const initialState: State = {
  stops: [],
  originalStops: [],
  saving: false,
};

export const loadRoute = (routeId: string) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.setCurrentRoute(routeId));
  await Promise.all([
    dispatch(cargoActions.loadCargosForRoute(routeId)).unwrap(),
    dispatch(getRouteStops(routeId)).unwrap(),
  ]);
};

export const setHoveredStopId =
  (stopId: string): AppThunkAction =>
  (dispatch, getState) => {
    const stop = getState().app.routeInfo.stops.stops?.find(
      (x) => "stopId" in x && x.stopId === stopId
    );
    if (stop && "cargoId" in stop) {
      dispatch(setHoveredCargoId(stop.cargoId));
    }
  };

const isOrderValid = (_stops: null | RouteStop[], state: RootState) => {
  // if (!stops) {
  // return false;
  // }

  const stops = selectors.selectRouteStopsAndWaypoints(state);
  if (!stops) return false;

  const initialAcc: Record<string, "PICKED_UP" | "DROPPED_OFF" | "INVALID"> =
    {};
  const result = stops.reduce((prev, current) => {
    if (current.type !== "CARGO_STOP") {
      return prev;
    }
    const currentState = prev[current.cargoId];
    switch (current.stopType) {
      case "PICKUP": {
        if (currentState === "DROPPED_OFF" || currentState === "INVALID") {
          return { ...prev, [current.cargoId]: "INVALID" as const };
        } else {
          return { ...prev, [current.cargoId]: "PICKED_UP" as const };
        }
      }
      case "DROPOFF": {
        if (currentState === "PICKED_UP" || currentState === "DROPPED_OFF") {
          return { ...prev, [current.cargoId]: "DROPPED_OFF" as const };
        } else {
          return { ...prev, [current.cargoId]: "INVALID" as const };
        }
      }
      default:
        throw new Error();
    }
  }, initialAcc);
  const actualValues = values(result);
  return !actualValues.includes("INVALID");
};

export const commit = createErrorReportingAsyncThunk(
  `${prefix}/commit`,
  async (_, { dispatch, getState }) => {
    const appState = getState().app.routeInfo;
    const routeId = appState.stops.routeId;
    const stops = appState.stops.stops;
    const originalStops = appState.stops.originalStops;
    if (stops === originalStops) {
      return null;
    }
    if (!isOrderValid(stops, getState())) {
      dispatch(
        notificationActions.notifyL({
          namespace: "notifications",
          key: "invalidStopOrder",
          type: "warning",
        })
      );
      return null;
    }
    // const data = stops && stops.map(({ stopId }) => ({ id: stopId }));
    if (!stops) {
      throw new Error(
        "We are in an invalid state, trying to update route stops when no data present"
      );
    }
    const toMove = appState.stops.movedStop;
    const movedStopId = toMove?.id;
    let newIndex = stops?.findIndex((x) => x.id === movedStopId);
    const originalIndex = appState.stops.originalStops?.findIndex(
      (x) => x.id === movedStopId
    );
    if (newIndex === undefined) {
      throw new Error("Cannot commit, not stop is being moved");
    }
    const movedStop = stops[newIndex];
    if (newIndex > (originalIndex || -1)) {
      newIndex++;
    }
    const result = await axios
      .put(
        `/api/routes/${routeId}/stops`,
        moveStopRequestT.encode({ id: movedStop.id, newIndex })
      )
      .then(getData)
      .then(tPromise.decode(routeInfoStopsT));
    return {
      routeId,
      stops: result.map((item) => ({
        ...item,
        stopId: (item as any).stopId || item.id,
      })),
    };
  }
);

export const moveTo =
  ({
    sourceStopId,
    destStopId,
    position,
  }: {
    sourceStopId: string;
    destStopId: string;
    position: "ABOVE" | "BELOW";
  }): AppThunkAction =>
  (dispatch, getState) => {
    const stops = getState().app.routeInfo.stops.stops;
    if (!stops) {
      return;
    }
    const sourceIndex = stops.findIndex((x) => x.id === sourceStopId);
    const destIndex = stops.findIndex((x) => x.id === destStopId);
    if (sourceIndex !== -1) {
      if (sourceIndex === destIndex) {
        return;
      }
      if (sourceIndex === destIndex + (position === "ABOVE" ? -1 : 1)) {
        return;
      }
    }
    dispatch(
      moveStop({
        id: sourceStopId,
        newIndex: destIndex + (position === "BELOW" ? 1 : 0),
      })
    );
  };

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setCurrentRoute(state, action: PayloadAction<string>) {
      if (state.routeId !== action.payload) {
        state.stops = [];
        state.routeId = action.payload;
      }
    },
    moveStop(state, action: PayloadAction<{ id: string; newIndex: number }>) {
      const { id, newIndex } = action.payload;
      if (!state.stops) {
        return;
      }
      const current = state.stops.find((x) => x.id === id) || state.movedStop;
      const currentIndex = current && state.stops.indexOf(current);
      if (!current) {
        return;
      }
      if (typeof currentIndex === "undefined" || currentIndex === -1) {
        state.stops.splice(newIndex, 0, current);
        return;
      }
      if (currentIndex === newIndex) {
        return;
      }
      if (currentIndex > newIndex) {
        state.stops.splice(currentIndex, 1);
        state.stops.splice(newIndex, 0, current);
      } else {
        state.stops.splice(newIndex, 0, current);
        state.stops.splice(currentIndex, 1);
      }
    },
    mouseOut(state) {
      if (state.saving) {
        return;
      }
      const idx = state.stops?.findIndex(
        (x) => state.movedStop && x.id === state.movedStop?.id
      );
      if (typeof idx !== "undefined" && idx !== -1) {
        state.stops?.splice(idx, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRouteStops.fulfilled, (state, action) => {
        if (action.meta.arg === state.routeId) {
          state.originalStops = action.payload;
          state.stops = action.payload;
        }
      })
      .addCase(beginDragStop, (state, action) => {
        state.movedStop = state.originalStops?.find(
          (x) => x.id === action.payload.routeStopId
        );
      })
      .addCase(commit.pending, (state) => {
        state.saving = true;
      })
      .addCase(commit.fulfilled, (state, action) => {
        if (action.payload) {
          state.originalStops = action.payload.stops;
          state.stops = action.payload.stops;
        } else {
          state.stops = state.originalStops;
        }
        state.saving = false;
        delete state.movedStop;
      })
      .addCase(commit.rejected, (state) => {
        state.stops = state.originalStops;
        state.saving = false;
        delete state.movedStop;
      })
      .addCase(stopOrderUpdated, (state, action) => {
        if (state.routeId === action.payload.routeId) {
          state.stops = action.payload.stops;
          state.originalStops = action.payload.stops;
        }
      })
      .addCase(endDrag, (state) => {
        if (!state.saving) {
          state.stops = state.originalStops;
          delete state.movedStop;
        }
      });
  },
});

export const { moveStop, mouseOut } = slice.actions;
export default slice.reducer;
