import DoraLinkButton from "../../Toolkit/DoraLinkButton";
import { useTranslation } from "react-i18next";
import "./ShipmentDialogClientInfoMailLinks.scss";
import { useFormContext } from "react-hook-form";
import CreateShipmentData, {
  CreateEditShipmentStop,
} from "./CreateEditShipmentData";
import { useSelector } from "../../../redux-store";
import { selectClients } from "../../../ducks/data/clients/selectors";
import { useL10n } from "../../../l10n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const ShipmentDialogClientInfoMailLinks = () => {
  const { t } = useTranslation(["shipmentModal", "types"]);
  const l10n = useL10n();
  const clients = useSelector(selectClients);

  const { getValues } = useFormContext<CreateShipmentData>();

  const addStopMailContent = (
    stop: CreateEditShipmentStop,
    mailBody: (string | null | undefined)[]
  ) => {
    mailBody.push(stop.place?.placeName);
    mailBody.push(stop.place?.address);
    const countryPostcode = [stop.place?.country, stop.place?.postcode]
      .filter(Boolean)
      .join(" ");
    mailBody.push(countryPostcode);
    const dateTimeFix = [
      stop.date && l10n.formatDate(stop.date.toISODate()),
      stop.time,
      stop.fix && "FIX",
    ]
      .filter(Boolean)
      .join(" ");
    mailBody.push(dateTimeFix);
    mailBody.push(stop.contact && `Kontakt: ${stop.contact}`);
    mailBody.push(stop.contactPhone && `Tlf.: ${stop.contactPhone}`);
    mailBody.push(stop.ref && `Reference: ${stop.ref}`);
    mailBody.push(stop.tag && `CMR mærke: ${stop.tag}`);
    const lmColliWeightCubic = [
      stop.loadmeters && `${stop.loadmeters} lm`,
      stop.colli && `${stop.colli.toString()} kolli`,
      stop.weight && `${stop.weight.toString()} kg`,
      stop.cubicMeters && `${stop.cubicMeters.toString()} m³`,
    ]
      .filter(Boolean)
      .join(", ");
    mailBody.push(lmColliWeightCubic);
    const dimensions = [
      stop.length && `l: ${stop.length.toString()} cm`,
      stop.width && `b: ${stop.width.toString()} cm`,
      stop.height && `h: ${stop.height.toString()} cm`,
    ]
      .filter(Boolean)
      .join(" x ");
    mailBody.push(dimensions);
    for (const p of stop.pieces) {
      mailBody.push(`${p.quantity} x ${p.unit}`);
    }
    mailBody.push("  ");
  };

  const getMailtoLink = ({
    includeClientInfo,
    subject,
  }: {
    includeClientInfo: boolean;
    subject: string;
  }) => {
    const values = getValues();
    const to = values.clientContactEmail;
    const body = getMailBody(includeClientInfo);
    return `mailto:${to}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  const getMailBody = (includeClientInfo: boolean) => {
    const mailBody = [];
    const values = getValues();
    mailBody.push(`Sendingsreference: ${values.ref}`);
    mailBody.push(t("types:cargoTypes.type", { context: values.type }));
    mailBody.push(values.description && `Beskrivelse: ${values.description}`);
    if (includeClientInfo) {
      const client = clients.find((c) => c.id === values.clientId);
      mailBody.push(client?.client && `Kunde: ${client?.client || ""}`);
      mailBody.push(
        values.invoiceRef && `Fakturareference: ${values.invoiceRef || ""}`
      );
      mailBody.push(values.clientContact && `Kontakt: ${values.clientContact}`);
      mailBody.push(
        values.clientContactEmail && `Email: ${values.clientContactEmail}`
      );
      mailBody.push(
        values.clientContactPhone && `Tlf.: ${values.clientContactPhone}`
      );
    }
    mailBody.push(
      "-------------------------------------------------------------"
    );
    for (const pickup of values.pickupList) {
      addStopMailContent(pickup, mailBody);
    }
    for (const dropoff of values.dropoffList) {
      addStopMailContent(dropoff, mailBody);
    }
    return mailBody.filter(Boolean).join("\n");
  };

  const onOrderConfirmationClick = () => {
    const shipmentRef = getValues("ref");
    window.location.href = getMailtoLink({
      subject: `Ordrebekræftelse: (${shipmentRef})`,
      includeClientInfo: true,
    });
  };

  const onInstructionsClick = () => {
    const shipmentRef = getValues("ref");
    window.location.href = getMailtoLink({
      subject: `Instruks: (${shipmentRef})`,
      includeClientInfo: false,
    });
  };

  return (
    <>
      <DoraLinkButton
        className="shipment-dialog__mail-button"
        onClick={onOrderConfirmationClick}
      >
        <FontAwesomeIcon icon={faEnvelope} />
        <span>{t("shipmentModal:orderConfirmation")}</span>
      </DoraLinkButton>
      <DoraLinkButton
        className="shipment-dialog__mail-button mt-2"
        onClick={onInstructionsClick}
      >
        <FontAwesomeIcon icon={faEnvelope} />
        <span>{t("shipmentModal:instructions")}</span>
      </DoraLinkButton>
    </>
  );
};

export default ShipmentDialogClientInfoMailLinks;
