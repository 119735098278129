import React, { useEffect } from "react";
import {
  CargoData,
  CargoFormData,
} from "../../../ducks/app/cargo-dialog/types";
import { useAppDispatch, useSelector } from "../../../redux-store";
import {
  selectFormDataForWhenOpeningModal,
  selectIsSaving,
  selectResetFormData,
} from "../../../ducks/app/cargo-dialog/selectors";
import { deleteCargo, loadCargo } from "../../../ducks/app/cargo-dialog";
import * as cargoDialogActions from "../../../ducks/app/cargo-dialog";
import CreateEditShipmentDialog from "../CreateEditShipmentDialog";
import { useStableNavigate } from "../../StableNavigateContext";

type EditShipmentProps = {
  onClose: (props: { formData?: CargoFormData; cargoId?: string }) => void;
  cargoId?: string;
  routeId?: string;
};

const AddEditCargoModalWrapper = (props: EditShipmentProps) => {
  const { cargoId, routeId } = props;
  const [cargoData, setCargoData] = React.useState<CargoData | null>(null);
  const resetData = useSelector(selectResetFormData);
  const isSaving = useSelector(selectIsSaving);
  const dispatch = useAppDispatch();
  const formStartingData = useSelector(selectFormDataForWhenOpeningModal);
  const navigate = useStableNavigate();

  const onClose = ({
    formData,
    cargoId,
  }: {
    formData?: CargoFormData;
    cargoId?: string;
  }) => {
    setCargoData(null);
    props.onClose({ formData, cargoId });
  };

  useEffect(() => {
    if (cargoId) {
      dispatch(loadCargo(cargoId)).then((result) => {
        if (result.payload) {
          const data = (result.payload as any).data as CargoData; // TODO: can't infer correct type
          setCargoData(data);
        }
      });
    }
  }, [cargoId, dispatch]);

  const handleSubmit = async (
    formData: CargoData & {
      keepAlive?: boolean;
      submitType: "CREATE" | "UPDATE" | "DUPLICATE" | "CREATE_AND_MAKE_COPY";
      deleteStopsWithEvents?: boolean;
    }
  ) => {
    const res = await dispatch(
      cargoDialogActions.submit({
        ...formData,
        routeId,
        submitType: formData.submitType,
      })
    ).unwrap();
    if (!res.keepAlive) {
      setCargoData(null);
      navigate({ hash: "" });
    }

    return res;
  };

  const handleDelete = async () => {
    if (!cargoId) {
      console.error("No cargo template id to delete");
      return;
    }
    dispatch(deleteCargo(cargoId));
    // TODO: only navigate and close on success, how to do this?
    navigate({ hash: "" }, { replace: true });
  };

  const ShipmentDialog = CreateEditShipmentDialog<CargoFormData, CargoData>;

  return cargoData || (!cargoId && formStartingData) ? (
    <ShipmentDialog
      resetData={resetData!}
      initialData={cargoData || formStartingData!}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      onClose={onClose}
      routeId={routeId}
      isSaving={isSaving}
      createNew={!cargoId}
      isTemplate={false}
    />
  ) : null;
};

export default AddEditCargoModalWrapper;
