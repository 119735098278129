import { combineReducers } from "redux";
import deliveries from "./deliveries";
import cargoEmissions from "./cargo-emissions";
import products from "./products";
import economics from "./economics";
import clients from "./clients";
import expenseAccounts from "./expenseAccounts";
import driverSessions from "./driver-sessions";
import drivers from "./drivers";
import cargoDocuments from "./cargo-documents";
import stopDocuments from "./stop-documents";
import trailers from "./trailers";
import cargos from "./cargos";
import teams from "./teams";
import dispatchers from "./dispatchers";
import routeViewModels from "./route-views";
import cargoViews from "./cargo-views";
import consolidatedInvoicing from "./consolidated-invoices";
import routing from "./routing";
import webBookers from "./web-bookers";
import orders from "./orders";
import departments from "./departments";
import savedLocations from "./saved-locations";
import trackerDevices from "./tracker-devices";
import orgSettings from "./org-settings";
import finance from "./finance";
import archivedTrailers from "./archived-trailers";
import cargoEvents from "./cargo-events";
import liveView from "./live-view";
import cargosReport from "./cargos-report";
import schedules from "./schedules";
import extraCO2Types from "./extra-co2-types";
import calendarView from "./calendar-view";

export default combineReducers({
  deliveries,
  products,
  economics,
  clients,
  expenseAccounts,
  driverSessions,
  drivers,
  cargoDocuments,
  stopDocuments,
  trailers,
  archivedTrailers,
  cargos,
  teams,
  consolidatedInvoicing,
  dispatchers,
  routeViewModels,
  cargoViews,
  cargoEvents,
  routing,
  cargoEmissions,
  webBookers,
  orders,
  departments,
  savedLocations,
  trackerDevices,
  orgSettings,
  finance,
  liveView,
  cargosReport,
  schedules,
  extraCO2Types,
  calendarView,
});
