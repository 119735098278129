import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux-store";
import sortBy from "lodash/sortBy";
import * as types from "./types";

// Declare as constant to avoid unnecessary rerenderings.
const empty: string[] = [];

export const selectDraggedCargoId = (state: RootState) =>
  state.app.dispatching.dragState?.cargoId;

export const selectSourceRouteId = (state: RootState) =>
  state.app.dispatching.dragState?.sourceRouteId;

export const selectCurrentRouteId = (state: RootState) =>
  state.app.dispatching.dragState?.currentRouteId;

// const selectUnassignedCargoIds =
//
const selectOriginalUnassignedCargoIds = createSelector(
  (state: RootState) => state.app.dispatching.teamFilterOptions.cargos,
  (state: RootState) => state.app.dispatching.unassignedCargoSorting,
  (state: RootState) => state.data.cargoViews.entities,
  (state: RootState) => state.app.dispatching.originalUnassignedCargoIds,
  (state: RootState) => state.data.clients.entities,
  (teamFilters, sorting, entities, originalUnassignedCargoIds, clients) => {
    const cargos = originalUnassignedCargoIds
      .map((id) => entities[id])
      .filter((x) => x && !x.smartMatch)
      .filter(
        (x) => !teamFilters.length || teamFilters.includes(x.assignedTeamId)
      );
    if (sorting.sortProperty) {
      const { sortProperty, sortOrder } = sorting;
      const sortedCargos = sortBy(cargos, (cargo) => {
        switch (sortProperty) {
          case types.pickupSort:
            return cargo.firstStop?.date;
          case types.dropoffSort:
            return cargo.lastStop?.date;
          case types.ldmSort:
            return +cargo.totalLm;
          case types.createdAtSort:
            return cargo.createdAt;
          case types.pickupPostCodeSort:
            return `${cargo.firstStop?.countryCode}${cargo.firstStop?.postcode}`;
          case types.dropoffPostCodeSort:
            return `${cargo.lastStop?.countryCode}${cargo.lastStop?.postcode}`;
          case types.clientSort:
            return cargo.clientId ? clients[cargo.clientId]?.client : null;
          default:
            throw new Error("Unknown sort id");
        }
      });
      const sortedCargoIds = sortedCargos.map((x) => x.id);
      return sortOrder === "ASC" ? sortedCargoIds : sortedCargoIds.reverse();
    } else {
      if (sorting.overriddenOrder) {
        let sorted: string[] = [];
        for (const id of sorting.overriddenOrder) {
          if (cargos.some((x) => x.id === id)) {
            sorted.push(id);
          }
        }
        for (const cargo of cargos.filter((x) => !sorted.includes(x.id))) {
          sorted.push(cargo.id);
        }
        return sorted;
      }
      return cargos.map((x) => x.id);
    }
  }
);

const selectOriginalCargoOrder =
  (routeId: string | null) => (state: RootState) =>
    routeId
      ? state.data.routeViewModels.entities[routeId]?.cargoOrder
      : selectOriginalUnassignedCargoIds(state);

export const selectRouteCargoIds =
  (routeId: string | null) => (state: RootState) =>
    state.app.dispatching.dragState?.routeCargoIds[routeId || ""] ||
    selectOriginalCargoOrder(routeId)(state) ||
    empty;

export const selectUnassignedCargoIds = selectRouteCargoIds(null);

export const selectFilterOptions =
  (type: types.FilterType) => (state: RootState) =>
    state.app.dispatching.teamFilterOptions[type];

export const selectFilteredTeamIds = (state: RootState) =>
  selectFilterOptions("routes")(state);

export const selectCargoFilterTeamIds = (state: RootState) =>
  state.app.dispatching.teamFilterOptions.cargos;

export const selectAreCargoFiltersEnabled = (state: RootState) =>
  !!selectCargoFilterTeamIds(state).length;

export const selectAreRouteFiltersEnabled = (state: RootState) =>
  !!selectFilteredTeamIds(state).length;

export const selectShouldClearButtonBeDisabled = (state: RootState) =>
  !selectAreRouteFiltersEnabled(state) && !state.routes.sortProperty;
