import { CalendarViewRouteData } from "../../ducks/data/calendar-view/types";
import { useCalendarViewControls } from "./CalendarViewControlContext";
import timeToWidth from "./time-to-width";
import {
  CalendarViewRoutesStopsRowsRelativeHeightPlaceholder,
  CalendarViewRouteStopsRows,
} from "./CalendarViewRoutesStopsRows";
import { DateTime } from "luxon";
import clsx from "clsx";
import { useDrop } from "react-dnd";
import { CalendarViewDrawnStop } from "./interfaces";
import { useRef } from "react";
import { useAppDispatch, useSelector } from "../../redux-store";
import {
  moveStopInRoute,
  shiftCargoStopsTime,
} from "../../ducks/data/calendar-view";
import { moveCargo } from "../../ducks/dispatchActions";
import { CALENDAR_VIEW_DRAGGABLE_CARGO_STOP } from "./constants";
import { CARGO_LIST_DRAGGABLE_CARGO } from "../DispatchPage/CargoList/CargoCard";
import { selectStartDate } from "../../ducks/data/calendar-view/selectors";

// TODO: this really should be passed from one hook to another, but the right value doesn't get through. Still, it should be implemented properly
let g = false;
let c: number;

const CalendarViewRouteWithStops = ({
  route,
}: {
  route: CalendarViewRouteData;
}) => {
  const { routeStartTime, routeEndTime, routeStartDate, routeEndDate, id } =
    route;
  const { colWidth, viewTimeInterval } = useCalendarViewControls();
  const currentViewRightEdge =
    colWidth * (viewTimeInterval === "week" ? 7 : 1) * 24;

  const dispatch = useAppDispatch();

  const dropRef = useRef<HTMLDivElement>(null);

  const { groupStopsWhenMovingInSameRoute } = useCalendarViewControls();

  g = groupStopsWhenMovingInSameRoute;
  c = colWidth;

  const [, drop] = useDrop(() => ({
    accept: [CALENDAR_VIEW_DRAGGABLE_CARGO_STOP, CARGO_LIST_DRAGGABLE_CARGO],
    drop: (
      item: {
        drawnStop: CalendarViewDrawnStop;
        dragStartXOffset: number;
        simpleDragOffset: number;
      } | any,
      monitor
    ) => {
      if (monitor.getItemType() === CARGO_LIST_DRAGGABLE_CARGO) {
        const { cargoId } = item as { cargoId: string };
        dispatch(moveCargo({
          sourceRouteId: null,
          cargoId,
          destRouteId: route.id
        }));
      }
      if (monitor.getItemType() === CALENDAR_VIEW_DRAGGABLE_CARGO_STOP) {
        const clientOffset = monitor.getClientOffset();
        const { drawnStop, simpleDragOffset } = item;
        const offsetDiff = clientOffset!.x - simpleDragOffset;

        const dropDateTime = drawnStop.dateTime.plus({
          hours: offsetDiff / c,
        });

        const dropDateTime5MinutesSnap = dropDateTime.set({
          minute: Math.floor(dropDateTime.minute / 5) * 5,
        });

        if (drawnStop.type === "DROPOFF" && !g) {
          const restOfCargoPickups = route.cargos
            .find((cc) => cc.id === drawnStop.cargoId)
            ?.stops.filter((ss) => ss.type === "PICKUP" && !ss.startTimeAssumed);
          for (const pickup of restOfCargoPickups!) {
            let d = DateTime.fromFormat(pickup.date, "yyyy-MM-dd");
            let tt = pickup.startTime!;
            if (pickup.stopTime && !pickup.stopTimeAssumed) {
              tt = pickup.stopTime;
            }
            const [hh, mm] = tt.split(":");
            d = d.set({
              hour: parseInt(hh),
              minute: parseInt(mm),
            });
            if (d > dropDateTime5MinutesSnap) {
              alert("Can't move dropoff before pickups of same cargo");
              return;
            }
          }
        }

        if (route.id !== drawnStop.routeId) {
          dispatch(
            moveCargo({
              sourceRouteId: drawnStop.routeId,
              destRouteId: route.id,
              cargoId: drawnStop.cargoId,
            })
          );
          return;
        }

        if (g) {
          const diffInMinutes = Math.floor(
            dropDateTime5MinutesSnap.diff(drawnStop.dateTime, "minutes").minutes
          );
          dispatch(
            shiftCargoStopsTime({
              cargoId: drawnStop.cargoId,
              minutesAmount: diffInMinutes,
            })
          );
        } else {
          dispatch(
            moveStopInRoute({
              stopId: drawnStop.stopId,
              cargoId: drawnStop.cargoId,
              date: dropDateTime5MinutesSnap.toFormat("yyyy-MM-dd"),
              time: dropDateTime5MinutesSnap.toFormat("HH:mm"),
            })
          );
        }
      }
    },
  }));

  const startDate = useSelector(selectStartDate);

  const daysOffsetCalcStart =
    viewTimeInterval === "week"
      ? startDate.startOf("week")
      : startDate.startOf("day");

  const daysOffsetRouteStart = Math.ceil(
    DateTime.fromISO(routeStartDate).diff(daysOffsetCalcStart, "days").days
  );
  const daysOffsetRouteEnd = Math.ceil(
    DateTime.fromISO(routeEndDate).diff(daysOffsetCalcStart, "days").days
  );

  let leftEdge = true;
  let rightEdge = true;

  let posXStart = timeToWidth(routeStartTime, colWidth, daysOffsetRouteStart);
  if (posXStart < 0) {
    posXStart = 0;
    leftEdge = false;
  }

  let posXEnd = timeToWidth(routeEndTime, colWidth, daysOffsetRouteEnd);
  if (posXEnd > currentViewRightEdge) {
    posXEnd = currentViewRightEdge;
    rightEdge = false;
  }

  const width = posXEnd - posXStart;

  const classes = clsx("calendar-view-route-with-stops-inner", {
    "no-edge-left": !leftEdge,
    "no-edge-right": !rightEdge,
  });

  drop(dropRef);

  return (
    <div className="calendar-view-route-with-stops">
      <div
        className={classes}
        ref={dropRef}
        style={{
          width,
          left: posXStart,
        }}
      >
        <CalendarViewRouteStopsRows
          cargos={route.cargos}
          routeId={id}
          leftOffset={posXStart}
        />
      </div>
      <CalendarViewRoutesStopsRowsRelativeHeightPlaceholder
        cargos={route.cargos}
        routeId={id}
      />
    </div>
  );
};

export default CalendarViewRouteWithStops;
