import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CloseButton } from "../../dispatching-planning/common/close-button";
import "./GenericPromptDialog.css";
import DoraButton, { ButtonVariant } from "../../Toolkit/DoraButton";

const GenericPromptDialog = ({
  title,
  text,
  cancelText,
  continueText,
  continueButtonColor,
  onCancelClick,
  onContinueClick,
  focusOnContinueButton,
}: {
  title: string;
  text?: string;
  cancelText?: string;
  continueText?: string;
  continueButtonColor?: "error" | "warning" | "primary" | "secondary"; // add more as needed
  focusOnContinueButton?: boolean;
  onCancelClick: () => void;
  onContinueClick: () => void;
}) => {
  const { t } = useTranslation("components");
  const con = continueText || t("buttonLabels.continue");
  const cancel = cancelText || t("buttonLabels.cancel");
  let continueButtonVariant: ButtonVariant = "primary";
  switch (continueButtonColor) {
    case "primary":
      continueButtonVariant = "primary-filled";
      break;
    case "error":
      continueButtonVariant = "error";
      break;
  }
  return (
    <Dialog open onClose={onCancelClick} fullWidth maxWidth="xs">
      <DialogTitle>
        <div className="generic-prompt-dialog__title">
          <div>{title}</div>
          <CloseButton
            className={"generic-prompt-dialog__close-button"}
            onClick={onCancelClick}
          />
        </div>
      </DialogTitle>
      {text && (
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </DialogContent>
      )}

      <DialogActions>
        <Stack spacing={1} direction="row">
          <DoraButton onClick={onCancelClick} variant="ghost">
            {cancel}
          </DoraButton>
          <DoraButton
            autoFocus={focusOnContinueButton}
            variant={continueButtonVariant}
            onClick={onContinueClick}
            color={continueButtonColor || undefined}
          >
            {con}
          </DoraButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default GenericPromptDialog;
