import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../axios";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { CalendarViewData } from "./types";
import { AppDispatch, RootState } from "../../../redux-store";
import { DateTime } from "luxon";

const prefix = "data/calendar-view";

type State = {
  status: "initial" | "loading" | "success" | "error";
  data: CalendarViewData;
  startDate: DateTime;
  selectedInterval: string;
};

const initialState: State = {
  status: "initial",
  data: [],
  startDate: DateTime.utc(),
  selectedInterval: "day",
};

export const loadCalendarViewData = createErrorReportingAsyncThunk(
  `${prefix}/load-calendar-view-data`,
  async (_, { getState }) => {
    const calendarViewState = (getState() as RootState).data.calendarView;
    const interval = calendarViewState.selectedInterval;
    const startDate = calendarViewState.startDate;
    const response = await axios.get(
      `/api/calendar-view?interval=${interval}&startDate=${startDate.toFormat("yyyy-MM-dd")}`
    );
    return response.data;
  }
);

export const moveStopInRoute = createErrorReportingAsyncThunk(
  `${prefix}/update-stop-time`,
  async (
    {
      stopId,
      cargoId,
      date,
      time,
    }: {
      stopId: string;
      cargoId: string;
      date: string;
      time: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const response = await axios.post(`/api/calendar-view/move-stop-in-route`, {
      stopId,
      cargoId,
      date,
      time,
      interval: state.data.calendarView.selectedInterval,
      startDate: state.data.calendarView.startDate,
    });
    return response.data;
  }
);

export const shiftCargoStopsTime = createErrorReportingAsyncThunk(
  `${prefix}/shift-cargo-stops-time`,
  async (
    {
      cargoId,
      minutesAmount,
    }: {
      cargoId: string;
      minutesAmount: number;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const response = await axios.post(
      `/api/calendar-view/shift-cargo-stops-time`,
      {
        cargoId,
        minutesAmount,
        interval: state.data.calendarView.selectedInterval,
        startDate: state.data.calendarView.startDate.toFormat("yyyy-MM-dd"),
      }
    );
    return response.data;
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    changeSelectedInterval: (state, action) => {
      state.selectedInterval = action.payload;
    },
    increaseStartDate: (state) => {
      state.startDate = state.startDate.plus(
        state.selectedInterval === "day" ? { days: 1 } : { days: 7 }
      );
    },
    decreaseStartDate: (state) => {
      state.startDate = state.startDate.minus(
        state.selectedInterval === "day" ? { days: 1 } : { days: 7 }
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCalendarViewData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(loadCalendarViewData.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(loadCalendarViewData.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(moveStopInRoute.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(shiftCargoStopsTime.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { changeSelectedInterval, increaseStartDate, decreaseStartDate } =
  slice.actions;

export const changeSelectedIntervalAndLoadData =
  (interval: string) => (dispatch: AppDispatch) => {
    dispatch(changeSelectedInterval(interval));
    dispatch(loadCalendarViewData());
  };

export const previousIntervalSelected = (dispatch: AppDispatch) => {
  dispatch(decreaseStartDate());
  dispatch(loadCalendarViewData());
};

export const nextIntervalSelected = (dispatch: AppDispatch) => {
  dispatch(increaseStartDate());
  dispatch(loadCalendarViewData());
};

export default slice.reducer;
