import { RootState } from "../../../redux-store";
import { DateTime } from "luxon";

export const selectCargoView = (cargoId: string) => (state: RootState) =>
  state.data.cargoViews.entities[cargoId];

export const selectCargoViews = (cargoIds: string[]) => (state: RootState) =>
  cargoIds.map((id) => state.data.cargoViews.entities[id]);

export const selectCargoViewsBetweenDates =
  (cargoIds: string[], fromDate: DateTime | null, toDate: DateTime | null) =>
  (state: RootState) =>
    cargoIds
      .map((id) => state.data.cargoViews.entities[id])
      .filter((c) => {
        if (
          fromDate &&
          c.firstStop?.date &&
          c.lastStop?.date &&
          DateTime.fromISO(c.firstStop.date) < fromDate &&
          DateTime.fromISO(c.lastStop.date) < fromDate
        ) {
          return false;
        }
        if (
          toDate &&
          c.lastStop?.date &&
          c.firstStop?.date &&
          DateTime.fromISO(c.lastStop.date) > toDate &&
          DateTime.fromISO(c.firstStop.date) > toDate
        ) {
          return false;
        }
        return true;
      });