// TODO: move the driver notebook here too

import { createErrorReportingAsyncThunk } from "../../helpers";
import axios, { getData } from "../../../axios";
import * as tPromise from "io-ts-promise";
import { createSlice } from "@reduxjs/toolkit";
import {
  CargoTags,
  cargoTagsT,
  orgSettingsT,
  OrgSettings,
  NewShipmentDateOffset,
  newShipmentDateRequestResponseT,
  routeSearchIntervalRequestResponseT,
} from "./types";
import { notifyL } from "../../notifications";
import { assign } from "lodash";

const prefix = "data/org-settings";

const initialState: OrgSettings = {
  cargoTags: [],
  newShipmentDate: "0",
  driverCanEditCargos: false,
  hideInvoiceRef: false,
  hideLoadmeters: false,
  hideCargoDescription: false,
  routeSearchInterval: "0",
  showArrivedDepartureToDriver: false,
  showAddCubicMetersToDriver: false,
};

export const loadNewShipmentDate = createErrorReportingAsyncThunk(
  `${prefix}/loadNewShipmentDate`,
  async () => {
    return axios
      .get(`/api/org-settings/new-shipment-date`)
      .then(getData)
      .then(tPromise.decode(newShipmentDateRequestResponseT));
  }
);

export const loadRouteSearchInterval = createErrorReportingAsyncThunk(
  `${prefix}/loadRouteSearchInterval`,
  async () => {
    return axios
      .get(`/api/org-settings/route-search-interval`)
      .then(getData)
      .then(tPromise.decode(routeSearchIntervalRequestResponseT));
  }
);

export const saveNewShipmentDate = createErrorReportingAsyncThunk(
  `${prefix}/saveNewShipmentDate`,
  async (shipmentDate: NewShipmentDateOffset, thunkAPI) => {
    return axios
      .put(`/api/org-settings/new-shipment-date`, { value: shipmentDate })
      .then(() => {
        thunkAPI.dispatch(
          notifyL({
            type: "success",
            namespace: "notifications",
            key: "shipmentDateSaved",
          })
        );
      });
  }
);

export const saveRouteSearchInterval = createErrorReportingAsyncThunk(
  `${prefix}/saveRouteSearchInterval`,
  async (routeSearchInterval: NewShipmentDateOffset, thunkAPI) => {
    return axios
      .put(`/api/org-settings/route-search-interval`, {
        value: routeSearchInterval,
      })
      .then(() => {
        thunkAPI.dispatch(
          notifyL({
            type: "success",
            namespace: "notifications",
            key: "routeSearchIntervalSaved",
          })
        );
      });
  }
);

export const loadCargoTags = createErrorReportingAsyncThunk(
  `${prefix}/loadCargoTags`,
  async () => {
    return axios
      .get(`/api/org-settings/cargo-tags`)
      .then(getData)
      .then(tPromise.decode(cargoTagsT));
  }
);

export const saveCargoTags = createErrorReportingAsyncThunk(
  `${prefix}/saveCargoTags`,
  async (cargoTags: CargoTags, thunkAPI) => {
    return axios.put(`/api/org-settings/cargo-tags`, cargoTags).then(() => {
      thunkAPI.dispatch(
        notifyL({
          type: "success",
          namespace: "notifications",
          key: "cargoTagsSaved",
        })
      );
    });
  }
);

export const saveHideLoadmetersValue = createErrorReportingAsyncThunk(
  `${prefix}/saveHideLoadmeters`,
  async (hideLoadmeters: boolean, thunkAPI) => {
    return axios
      .put(`/api/org-settings/hide-loadmeters`, { value: hideLoadmeters })
      .then(() => {
        thunkAPI.dispatch(
          notifyL({
            type: "success",
            namespace: "notifications",
            key: "hideLoadmetersSaved",
          })
        );
      });
  }
);

export const loadHideCargoDescriptionValue = createErrorReportingAsyncThunk(
  `${prefix}/loadHideCargoDescription`,
  async () => {
    return axios
      .get(`/api/org-settings/hide-cargo-description`)
      .then(getData)
      .then((x) => x.value);
  }
);

export const saveHideInvoiceRefValue = createErrorReportingAsyncThunk(
  `${prefix}/saveHideInvoiceRef`,
  async (hideInvoiceRef: boolean, thunkAPI) => {
    return axios
      .put(`/api/org-settings/hide-invoice-ref`, { value: hideInvoiceRef })
      .then(() => {
        thunkAPI.dispatch(
          notifyL({
            type: "success",
            namespace: "notifications",
            key: "hideInvoiceRefSaved",
          })
        );
      });
  }
);

export const saveHideCargoDescriptionValue = createErrorReportingAsyncThunk(
  `${prefix}/saveHideCargoDescription`,
  async (hideCargoDescription: boolean, thunkAPI) => {
    return axios
      .put(`/api/org-settings/hide-cargo-description`, {
        value: hideCargoDescription,
      })
      .then(() => {
        thunkAPI.dispatch(
          notifyL({
            type: "success",
            namespace: "notifications",
            key: "hideCargoDescriptionSaved",
          })
        );
      });
  }
);

export const loadOrgSettings = createErrorReportingAsyncThunk(
  `${prefix}/loadOrgSettings`,
  async () => {
    return axios
      .get(`/api/org-settings`)
      .then(getData)
      .then(tPromise.decode(orgSettingsT));
  }
);

export const saveDriverCanEditCargosValue = createErrorReportingAsyncThunk(
  `${prefix}/saveDriverCanEditCargos`,
  async (driverCanEditCargos: boolean, thunkAPI) => {
    return axios
      .put(`/api/org-settings/driver-can-edit-cargos`, {
        value: driverCanEditCargos,
      })
      .then(() => {
        thunkAPI.dispatch(
          notifyL({
            type: "success",
            namespace: "notifications",
            key: "settingSaved",
          })
        );
      });
  }
);

export const saveToggleArrivedDepartedButtonsValue =
  createErrorReportingAsyncThunk(
    `${prefix}/saveToggleArrivedDepartedButtonsValue`,
    async (value: boolean) => {
      return axios
        .put(`/api/org-settings/toggle-arrived-departed-buttons`, { value })
        .then(() => value);
    }
  );

export const loadArrivedDepartedButtonsValue = createErrorReportingAsyncThunk(
  `${prefix}/loadArrivedDepartedButtonsValue`,
  async () => {
    return axios
      .get(`/api/org-settings/toggle-arrived-departed-buttons`)
      .then(getData)
      .then((x) => x.value);
  }
);

export const saveToggleAddCubicMetersButtonValue =
  createErrorReportingAsyncThunk(
    `${prefix}/saveToggleAddCubicMetersButtonValue`,
    async (value: boolean) => {
      return axios
        .put(`/api/org-settings/toggle-add-cubic-meters-button`, { value })
        .then(() => value);
    }
  );

export const loadAddCubicMetersButtonValue = createErrorReportingAsyncThunk(
  `${prefix}/loadAddCubicMetersButtonValue`,
  async () => {
    return axios
      .get(`/api/org-settings/toggle-add-cubic-meters-button`)
      .then(getData)
      .then((x) => x.value);
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadOrgSettings.fulfilled, (state, action) => {
      assign(state, action.payload);
    });
    builder.addCase(loadCargoTags.fulfilled, (state, action) => {
      state.cargoTags = action.payload;
    });
    builder.addCase(loadNewShipmentDate.fulfilled, (state, action) => {
      state.newShipmentDate = action.payload.value;
    });
    builder.addCase(
      loadHideCargoDescriptionValue.fulfilled,
      (state, action) => {
        state.hideCargoDescription = action.payload;
      }
    );
  },
});

export default slice.reducer;
