import { createAction, createSlice } from "@reduxjs/toolkit";
import * as tPromise from "io-ts-promise";
import axios, { getData } from "../../../axios";
import { createErrorReportingAsyncThunk } from "../../helpers";
import keyBy from "lodash/keyBy";
import * as types from "./types";
import { toggleSmartMatch } from "../../cargo";
import { getAllArchivedRoutes } from "../../routes";
import { AppThunkAction } from "../../../redux-store";
import { viewModelDecoded } from "../route-views";
import * as t from "io-ts";
import * as authSelectors from "../../auth/selectors";

const prefix = "data/cargo-views";

type State = {
  entities: Record<string, types.CargoView>;
};

const initialState: State = {
  entities: {},
};

const decode =
  <T extends t.Any>(codec: T) =>
  (input: unknown) => {
    const result = codec.decode(input);
    switch (result._tag) {
      case "Left":
        throw new Error("Decode error");
      case "Right":
        return result.right;
    }
  };

export const cargoViewDecoded = createAction<{
  cargoView: types.CargoView;
  myTeams: string[];
}>(`${prefix}/cargo-view-decoded`);

export const cargoViewUpdated =
  (view: unknown): AppThunkAction =>
  (dispatch, getState) => {
    const cargoView = decode(types.cargoViewT)(view);
    const myTeams = authSelectors.selectMyTeams(getState());
    dispatch(cargoViewDecoded({ cargoView, myTeams }));
  };

export const loadActiveCargo = createErrorReportingAsyncThunk(
  `${prefix}/load-active-cargo`,
  async () => {
    const result = axios
      .get("/api/cargo-views")
      .then(getData)
      .then(tPromise.decode(types.cargoViewsT));
    return result;
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(viewModelDecoded, (state, action) => {
        state.entities = {
          ...state.entities,
          ...keyBy(action.payload.cargos, "id"),
        };
      })
      .addCase(cargoViewDecoded, (state, action) => {
        const { cargoView } = action.payload;
        state.entities[cargoView.id] = cargoView;
      })
      .addCase(loadActiveCargo.fulfilled, (state, action) => {
        state.entities = {
          ...state.entities,
          ...keyBy(action.payload, "id"),
        };
      })
      .addCase(getAllArchivedRoutes.fulfilled, (state, action) => {
        const newState = keyBy(action.payload.cargoViews, "id");
        state.entities = newState;
      })
      .addCase(toggleSmartMatch.fulfilled, (state, action) => {
        const arg = action.meta.arg;
        const cargo = state.entities[arg];
        if (cargo) {
          cargo.smartMatch = !cargo.smartMatch;
        }
      });
  },
});

export default slice.reducer;
