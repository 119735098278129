import { useSelector } from "../../../redux-store";
import {
  selectTimeSpent,
  selectTotalWeight,
  selectWeightCargoEvents,
} from "../../../ducks/data/cargo-events/selectors";
import { useL10n } from "../../../l10n";
import { useTranslation } from "react-i18next";
import { PlannedLocation } from "../../../ducks/data/cargo-events/types";

const WeightEventsTotalWeightAndTimeSpent = () => {
  const { t } = useTranslation(["shipmentModal"]);
  const l10n = useL10n();
  const weightEvents = useSelector(selectWeightCargoEvents);
  const totalWeight = useSelector(selectTotalWeight);
  const timeSpent = useSelector(selectTimeSpent);

  const renderPlannedLocation = (plannedLocation: PlannedLocation) => {
    if (!plannedLocation) {
      return null;
    }
    if (plannedLocation.city) {
      return plannedLocation.city;
    }
    if (plannedLocation.place) {
      return plannedLocation.place;
    }
  };

  return (
    <>
      {weightEvents.length ? (
        <div className="shipment-dialog__total-weight">
          <div className="shipment-dialog__shipment-stat">
            <div>{t("shipmentModal:totalWeight")}</div>
            <div>{l10n.formatNumber(totalWeight)} kg</div>
          </div>
          <div className="total-weight__hover">
            {weightEvents.map((e, i) => (
              <div key={i}>
                {renderPlannedLocation(e.plannedLocation)} -{" "}
                {l10n.formatNumber(e.weight)} kg
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {timeSpent && (
        <div className="shipment-dialog__shipment-stat">
          <div>{t("shipmentModal:timeUsed")}</div>
          <div>{timeSpent}</div>
        </div>
      )}
    </>
  );
};

export default WeightEventsTotalWeightAndTimeSpent;
