import { RootState } from "../../../redux-store";
import { WeightAddedCargoEvent } from "./types";
import Decimal from "decimal.js-light";
import { DateTime } from "luxon";
import { createSelector } from "@reduxjs/toolkit";
// import { useTranslation } from "react-i18next";

export const selectCargoEvents = (state: RootState) =>
  state.data.cargoEvents.entities;

export const selectWeightCargoEvents = createSelector(
  selectCargoEvents,
  (events) =>
    events.filter(
      (event) => event.type === "CARGO_WEIGHT_ADDED"
    ) as WeightAddedCargoEvent[]
);

export const selectTotalWeight = createSelector(
  selectWeightCargoEvents,
  (events) =>
    events.reduce((acc, event) => acc.plus(event.weight), new Decimal(0))
);

export const selectTimeSpent = createSelector(selectCargoEvents, (events) => {
  // const { t } = useTranslation("shipmentModal");
  const timeRelevantEvents = events?.filter((e) =>
    [
      "CARGO_COLLECTED",
      "CARGO_DELIVERED",
      "DRIVER_ARRIVED",
      "DRIVER_DEPARTED",
    ].includes(e.type)
  );
  let value = "";
  if (timeRelevantEvents?.length) {
    timeRelevantEvents.sort((a, b) => {
      return new Date(a.time).getTime() - new Date(b.time).getTime();
    });
    const firstEvent = timeRelevantEvents[0];
    const lastEvent = timeRelevantEvents[timeRelevantEvents.length - 1];
    if (firstEvent !== lastEvent) {
      const lastEventTime = DateTime.fromISO(lastEvent.time).set({
        second: 0,
      });
      const firstEventTime = DateTime.fromISO(firstEvent.time).set({
        second: 0,
        millisecond: 0,
      });
      const diff = lastEventTime.diff(firstEventTime, ["hours", "minutes"]);
      const { hours, minutes } = diff.toObject();
      if (hours) {
        // value += hours.toFixed(0) + `${t("hoursAbbreviation")} `;
        value += hours.toFixed(0) + `t `;
      }
      if (minutes) {
        value += minutes.toFixed(0) + "m";
      }
    }
  }
  return value;
});
