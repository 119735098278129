import Decimal from "decimal.js-light";

const timeToWidth = (
  time: string,
  hourWidthInPx: number,
  daysOffset: number
) => {
  const hourWidthInPxDecimal = new Decimal(hourWidthInPx);
  const [h, m] = time.split(":");
  const hh = new Decimal(h);
  const mm = new Decimal(m).div(60);
  let hours = hh.plus(mm);
  if (daysOffset) {
    const daysOffsetInHours = new Decimal(daysOffset).times(24);
    hours = hours.plus(daysOffsetInHours);
  }
  return hours.times(hourWidthInPxDecimal).toNumber();
};

export default timeToWidth;
