import { CalendarViewControlsProvider } from "./CalendarViewControlContext";
import CalendarViewPage from "./CalendarViewPage";
import { useFeature } from "../../hooks";

const CalendarViewPageWrapper = () => {
  const isFeatureEnabled = useFeature("calendar-view-test-page");

  return isFeatureEnabled ? (
    <CalendarViewControlsProvider>
      <CalendarViewPage />
    </CalendarViewControlsProvider>
  ) : null;
};

export default CalendarViewPageWrapper;
