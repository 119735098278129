import { RootState } from "../../../redux-store";

export const selectSessionsForRoute =
  (routeId: string) => (state: RootState) => {
    return state.data.driverSessions.byRouteId[routeId];
  };

export const selectCompletedStop = (stopId: string) => (state: RootState) => {
  return state.data.driverSessions.completedStops[stopId];
};

export const selectIsStopCompleted =
  (stopId: string | null | undefined) => (state: RootState) => {
    return stopId ? !!state.data.driverSessions.completedStops[stopId] : false;
  };
