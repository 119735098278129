import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { changeSelectedIntervalAndLoadData } from "../../ducks/data/calendar-view";
import { useAppDispatch, useSelector } from "../../redux-store";
import { selectViewTimeInterval } from "../../ducks/data/calendar-view/selectors";

type CalendarViewControlsContextType = {
  zoomFactor: number;
  increaseZoom: () => void;
  decreaseZoom: () => void;
  colWidth: number;
  setShowCollisionsMultipleRows: (value: boolean) => void;
  showCollisionsMultipleRows: boolean;
  viewTimeInterval: string;
  onTimeSelectionClick: (interval: string) => void;
  groupStopsWhenMovingInSameRoute: boolean;
  setGroupStopsWhenMovingInSameRoute: (value: boolean) => void;
};

const CalendarViewControlsContext = createContext<
  CalendarViewControlsContextType | undefined
>(undefined);

export const CalendarViewControlsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [zoomFactor, setZoomFactor] = useState(1);
  const [showCollisionsMultipleRows, setShowCollisionsMultipleRows] =
    useState(false);
  // TODO: move this out of the controls?
  const viewTimeInterval = useSelector(selectViewTimeInterval);
  const [groupStopsWhenMovingInSameRoute, setGroupStopsWhenMovingInSameRoute] =
    useState(true);

  const dispatch = useAppDispatch();

  const increaseZoom = () => {
    setZoomFactor((prev) => prev + 0.4);
  };

  const decreaseZoom = () => {
    setZoomFactor((prev) => prev - 0.4);
  };

  const onTimeSelectionClick = (interval: string) => {
    setZoomFactor(1);
    dispatch(changeSelectedIntervalAndLoadData(interval));
  };

  const colWidth = useMemo(
    () => Math.ceil((zoomFactor * 55) / (viewTimeInterval === "week" ? 7 : 1)),
    [zoomFactor, viewTimeInterval]
  );

  return (
    <CalendarViewControlsContext.Provider
      value={{
        zoomFactor,
        increaseZoom,
        decreaseZoom,
        colWidth,
        showCollisionsMultipleRows,
        setShowCollisionsMultipleRows,
        viewTimeInterval,
        onTimeSelectionClick,
        groupStopsWhenMovingInSameRoute,
        setGroupStopsWhenMovingInSameRoute,
      }}
    >
      {children}
    </CalendarViewControlsContext.Provider>
  );
};

export const useCalendarViewControls = () => {
  const context = useContext(CalendarViewControlsContext);
  if (!context) {
    throw new Error(
      "useCalendarViewControls must be used within a CalendarViewControlsProvider"
    );
  }
  return context;
};
