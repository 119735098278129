const cargoColorList = [
  // "#90EE90", // Light Green
  "#ADD8E6", // Light Blue
  // "#F08080", // Light Coral
  // "#FFFFE0", // Light Yellow
  "#FFC0CB", // Pink
  "#00FFFF", // Cyan
  "#FFB6C1", // Light Pink
  "#FFA07A", // Light Salmon
  "#FFD700", // Gold
  "#E6E6FA", // Lavender
  // "#98FB98", // Pale Green
  "#AFEEEE", // Pale Turquoise
  "#DB7093", // Pale Violet Red
  "#FF69B4", // Hot Pink
  "#FF6347", // Tomato
  "#FFDAB9", // Peach Puff
  "#E0FFFF", // Light Cyan
  // "#F0E68C", // Khaki
  // "#FAFAD2", // Light Goldenrod Yellow
  "#D8BFD8", // Thistle
  "#FFDEAD", // Navajo White
  "#F5DEB3", // Wheat
  // "#FFFACD", // Lemon Chiffon
  "#FFE4B5", // Moccasin
  "#FFB6C1", // Light Pink
  "#FFA07A", // Light Salmon
  "#FFD700", // Gold
  "#E6E6FA", // Lavender
  "#98FB98", // Pale Green
  "#AFEEEE", // Pale Turquoise
  "#DB7093", // Pale Violet Red
  "#FF69B4", // Hot Pink
  "#FF6347", // Tomato
  "#FFDAB9", // Peach Puff
  "#E0FFFF", // Light Cyan
  "#F0E68C", // Khaki
];

export default cargoColorList;
