import {
  CalendarViewRouteCargoData,
  CalendarViewRouteStopData,
} from "../../ducks/data/calendar-view/types";
import { useCalendarViewControls } from "./CalendarViewControlContext";
import { useMemo } from "react";
import timeToWidth from "./time-to-width";
import { CalendarViewDrawnStop } from "./interfaces";

import cargoColors from "./calendar-view-cargo-colors";
import { DateTime } from "luxon";
import CalendarViewStop from "./CalendarViewStop";
import { useSelector } from "../../redux-store";
import { selectStartDate } from "../../ducks/data/calendar-view/selectors";

const getDrawnStopDateTime = (stop: CalendarViewRouteStopData) => {
  return DateTime.fromISO(stop.date!).plus({
    hours: parseInt(stop.startTime!.split(":")[0]!),
    minutes: parseInt(stop.startTime!.split(":")[1]!),
  });
};

export const CalendarViewRouteStopsRows = ({
  cargos,
  leftOffset,
  routeId,
}: {
  cargos: CalendarViewRouteCargoData[];
  leftOffset: number;
  routeId: string;
}) => {
  const { colWidth, showCollisionsMultipleRows, viewTimeInterval } =
    useCalendarViewControls();

  const startDate = useSelector(selectStartDate);

  let daysOffsetStart = startDate;
  if (viewTimeInterval === "week") {
    daysOffsetStart = startDate.startOf("week");
  }

  const allRouteStops = cargos.flatMap((c) =>
    c.stops.map((s) => ({
      ...s,
      cargoId: c.id,
      routeId,
    }))
  );

  const cargoColorsMap = useMemo(() => {
    let i = 0;
    return cargos.reduce((acc: { [cargoId: string]: string }, cargo) => {
      if (!cargoColors[i]) {
        i = 0;
      }
      acc[cargo.id] = cargoColors[i];
      i++;
      return acc;
    }, {});
  }, [cargos]);

  const data = useMemo(() => {
    if (!allRouteStops.length) {
      return {
        rowsWithStops: [],
        collisionIntervals: [],
      };
    }
    const rowsWithStopsTemp: CalendarViewDrawnStop[][] = [];
    const collisionIntervals: { start: number; stop: number; width: number }[] =
      [];
    for (const stop of allRouteStops) {
      const color = cargoColorsMap[stop.cargoId];
      const daysOffset = Math.ceil(
        DateTime.fromISO(stop.date!).diff(daysOffsetStart, "days").days
      );
      const startPosX = timeToWidth(stop.startTime!, colWidth, daysOffset);
      const endPosX = timeToWidth(stop.stopTime!, colWidth, daysOffset);
      const width: number = endPosX - startPosX || 1;
      let added = false;
      for (const rowWithStops of rowsWithStopsTemp) {
        if (added) {
          continue;
        }
        const stopsWithWhichCollides = rowWithStops.filter((s) => {
          const c = s.endPosX > startPosX && s.startPosX < endPosX;
          if (c) {
            const start = Math.max(s.startPosX, startPosX);
            const stop = Math.min(s.endPosX, endPosX);
            const collisionSegment = {
              start,
              stop,
              width: Math.max(stop - start - 4, 2), // should have some width at least
            };
            collisionIntervals.push(collisionSegment); // TODO: side effect in filter is bad
          }
          return c;
        });
        if (stopsWithWhichCollides.length) {
          for (const s of stopsWithWhichCollides) {
            s.collides = true;
          }
          break;
        }
        rowWithStops.push({
          startPosX,
          endPosX,
          width,
          color,
          place: stop.place,
          startTimeAssumed: stop.startTimeAssumed,
          stopTimeAssumed: stop.stopTimeAssumed,
          collides: false,
          stopId: stop.id,
          cargoId: stop.cargoId,
          routeId,
          dateTime: getDrawnStopDateTime(stop),
          type: stop.type,
        });
        added = true;
      }

      if (!added) {
        rowsWithStopsTemp.push([
          {
            startPosX,
            endPosX,
            width,
            color,
            place: stop.place,
            collides: !!rowsWithStopsTemp.length,
            startTimeAssumed: stop.startTimeAssumed,
            stopTimeAssumed: stop.stopTimeAssumed,
            stopId: stop.id,
            cargoId: stop.cargoId,
            routeId,
            dateTime: getDrawnStopDateTime(stop),
            type: stop.type,
          },
        ]);
      }
    }
    return {
      rowsWithStops: rowsWithStopsTemp,
      collisionIntervals,
    };
  }, [colWidth, allRouteStops]);

  const { rowsWithStops, collisionIntervals } = data;

  return (
    <>
      {rowsWithStops?.map((rowWithStops, i) => (
        <div
          key={i}
          className="calendar-view-row-height"
          style={{
            position: showCollisionsMultipleRows ? "relative" : "absolute",
          }}
        >
          {rowWithStops.map((drawnStop, j) => (
            <CalendarViewStop
              leftOffset={leftOffset}
              drawnStop={drawnStop}
              key={`${i}-${j}`}
            />
          ))}
          {!showCollisionsMultipleRows &&
            collisionIntervals.map((collisionInterval, i) => (
              <div
                key={i}
                className="calendar-view-stops-collision"
                style={{
                  left: collisionInterval.start - leftOffset,
                  width: collisionInterval.width,
                }}
              ></div>
            ))}
        </div>
      ))}
    </>
  );
};

// TODO: this component is a hack to force a relative parent to have the height as if it's children were positioned also relatively and not absolutely
// In order to see what it does simply deleted it while the routes are expanded and see the changes
export const CalendarViewRoutesStopsRowsRelativeHeightPlaceholder = ({
  cargos,
  routeId,
}: {
  cargos: CalendarViewRouteCargoData[];
  routeId: string;
}) => {
  const { showCollisionsMultipleRows, colWidth, viewTimeInterval } =
    useCalendarViewControls();

  const startDate = useSelector(selectStartDate);

  const allRouteStops = cargos.flatMap((c) =>
    c.stops.map((s) => ({
      ...s,
      cargoId: c.id,
    }))
  );

  let daysOffsetStart = startDate;
  if (viewTimeInterval === "week") {
    daysOffsetStart = startDate.startOf("week");
  }

  const data = useMemo(() => {
    if (!allRouteStops.length) {
      return { rowsWithStops: [] };
    }
    const rowsWithStopsTemp: CalendarViewDrawnStop[][] = [];
    for (const stop of allRouteStops) {
      const daysOffset = Math.ceil(
        DateTime.fromISO(stop.date!)
          .startOf("day")
          .diff(daysOffsetStart, "days").days
      );
      const startPosX = timeToWidth(stop.startTime!, colWidth, daysOffset);
      const endPosX = timeToWidth(stop.stopTime!, colWidth, daysOffset);
      const width: number = endPosX - startPosX;
      let added = false;
      for (const rowWithStops of rowsWithStopsTemp) {
        const stopsWithWhichCollides = rowWithStops.filter(
          (s) => s.endPosX > startPosX && s.startPosX < endPosX
        );
        if (stopsWithWhichCollides.length) {
          for (const s of stopsWithWhichCollides) {
            s.collides = true;
          }
          break;
        }
        rowWithStops.push({
          startPosX,
          endPosX,
          width,
          color: "cyan",
          place: stop.place,
          collides: false,
          startTimeAssumed: stop.startTimeAssumed,
          stopTimeAssumed: stop.stopTimeAssumed,
          stopId: stop.id,
          cargoId: stop.cargoId,
          routeId,
          dateTime: getDrawnStopDateTime(stop),
          type: stop.type,
        });
        added = true;
      }

      if (!added) {
        rowsWithStopsTemp.push([
          {
            startPosX,
            endPosX,
            width,
            color: "cyan",
            place: stop.place,
            collides: !!rowsWithStopsTemp.length,
            startTimeAssumed: stop.startTimeAssumed,
            stopTimeAssumed: stop.stopTimeAssumed,
            stopId: stop.id,
            cargoId: stop.cargoId,
            routeId,
            dateTime: getDrawnStopDateTime(stop),
            type: stop.type,
          },
        ]);
      }
    }
    return { rowsWithStops: rowsWithStopsTemp };
  }, [allRouteStops, colWidth]);

  const { rowsWithStops } = data;
  return (
    <>
      {rowsWithStops.map((_, i) => (
        <div
          key={i}
          className="calendar-view-row-height"
          style={{
            position: showCollisionsMultipleRows ? "relative" : "absolute",
            background: "cyan",
            width: 1,
            zIndex: -1,
          }}
        ></div>
      ))}
    </>
  );
};
