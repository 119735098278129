import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux-store";

const selectEntities = (state: RootState) => state.data.trailers.entities;
const selectIds = (state: RootState) => state.data.trailers.ids;

export const selectTrailers = createSelector(
  selectIds,
  selectEntities,
  (ids, entities) => ids.map((id) => entities[id])
);

export const selectTrailer = (id?: string | null) => (state: RootState) =>
  !id ? undefined : state.data.trailers.entities[id];

export const selectTrailerType = (id: string | null) => (state: RootState) =>
  (id && state.data.trailers.entities[id]?.type) || null;

export const selectTrailerDepartmentIdForCargo =
  (cargoId: string) => (state: RootState) => {
    const cargo = state.data.cargos.entities[cargoId];
    if (!cargo) {
      return null;
    }
    const route = Object.values(state.data.routeViewModels.entities).find(
      (route) => route && route.cargoOrder.includes(cargoId)
    );
    if (!route || !route.trailerId) {
      return null;
    }
    return state.data.trailers.entities[route.trailerId].departmentId || null;
  };
