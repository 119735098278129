import "./NewsModalContent.css";
import DoraButton from "../../Toolkit/DoraButton";
// import Pricing from "./pricing-agreement.png";
// import Map from "./maps.png";
// import { useState } from "react";

const NewsModalContent = ({ onClose }: { onClose: () => void }) => {
  // const [step, setStep] = useState(0);

  return (
    <>
      {/* {step === 0 && (
        <> */}
      <p className="news-modal-content__helpertext">25.08.2024</p>
      {/* <h1 className="news-modal-content__title">
        Nu er alt med chaufførapp og webbooking oppe at køre igen.
      </h1> */}
      <div className="news-modal-content__text">
        <p>Nogle brugere kan i øjeblikket opleve problemer. </p>
        <p>Der arbejdes på at løse det hurtigst muligt.</p>
        {/* <p>Sådan gør du:</p>
        <ul>
          <li>
            Klik på det orange tandhjul oppe i højre hjørne, og klik på
            "Kunder".
          </li>
          <li>Vælg den kunde, du ønsker at gemme en prisaftale på.</li>
        </ul>
        <div className="news-modal-content__img-container">
          <img
            src={Pricing}
            alt="Knapper"
            style={{ width: "90%", marginBottom: 2 }}
          />
          <p className="news-modal-content__helpertext">
            Billede: Sådan ser prisaftaler på den enkelte kunde ud.
          </p>
        </div> */}
        {/*  /!* <p className="news-modal-content__helpertext">*/}
        {/*        <strong>Sådan gør du:</strong> Gå til indstillinger ved at klikke*/}
        {/*        på dit navn oppe i højre hjørne. Klik så på menupunktet*/}
        {/*        "Opsætning" for at ændre dato og visning af ladmeter.*/}
        {/*      </p> *!/*/}
      </div>
      <div className="flex justify-end gap-2">
        {/* <DoraButton variant="primary" onClick={() => setStep(1)}>
              Videre
            </DoraButton> */}
        <DoraButton variant="primary" onClick={onClose}>
          Ok
        </DoraButton>
      </div>
      {/* </>
      )} */}
      {/* {step === 1 && (
        <>
          <h1 className="news-modal-content__title">
            Se live trafikinfo på kortet
          </h1>
          <p className="news-modal-content__helpertext">03.04.2024</p>
          <div className="news-modal-content__text">
            <p>
              Zoom ind på kortet og se opdateringer om trafikken i nærområdet.
            </p>
          </div>
          <div className="news-modal-content__img-container">
            <img src={Map} alt="Map" style={{ width: "90%" }} />
            <p className="news-modal-content__helpertext">
              Eksempel på kort fra live view
            </p>
          </div>
          <p className="news-modal-content__helpertext">
            <strong>Sådan gør du:</strong> Gå til "Kunder" under indstillinger
            og klik på en kunde. Her kan du tilpasse skabelonen til den enkelte
            kunde.
          </p>
          <div className="flex justify-end gap-2">
            <DoraButton variant="primary" onClick={() => setStep(0)}>
              Tilbage
            </DoraButton>
            <DoraButton variant="primary" onClick={onClose}>
              Ok
            </DoraButton>
          </div>
        </>
      )} */}
    </>
  );
};

export default NewsModalContent;
