import * as t from "io-ts";
import * as tPromise from "io-ts-promise";
import { createCargoTemplateTypes } from "dora-contracts";
import { CargoFormDataBase } from "../cargo-and-template-shared";

export const {
  editCargoTemplateInputT,
  editCargoTemplateOutputT,
  postCargoTemplateT,
  editStopOutputT,
} = createCargoTemplateTypes({
  decimal: t.string,
  date: t.string,
});

export const decodeCargoTemplate = tPromise.decode(editCargoTemplateOutputT);

export interface CargoTemplateFormData extends CargoFormDataBase {}

export type CargoTemplateData = CargoTemplateFormData & {
  id?: string; // cargo template id
  userId?: string | null; // always null here
}; // TODO: what to add here?
